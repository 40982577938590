import backend from '../../api/backend';
import {
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILED,
  GET_EVENT_FAILED,
  CREATE_TICKET_REQUEST,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_FAILED,
  CREATE_INSURANCE_REQUEST,
  CREATE_INSURANCE_SUCCESS,
  CREATE_INSURANCE_FAILED,
  REMOVE_TICKET_REQUEST,
  REMOVE_TICKET_SUCCESS,
  REMOVE_TICKET_FAILED,
  CLEAR_INSURANCE_REQUEST,
  CLEAR_INSURANCE_SUCCESS,
  CLEAR_INSURANCE_FAILED,
  ATHLETE_INFO_REQUEST,
  ATHLETE_INFO_SUCCESS,
  ATHLETE_INFO_FAILED,
  CLEAR_ATHLETE_INFO_REQUEST,
  CLEAR_ATHLETE_INFO_SUCCESS,
  CLEAR_ATHLETE_INFO_FAILED,
  ADMIN_EVENTS_INFO_REQUEST,
  ADMIN_EVENTS_INFO_SUCCESS,
  ADMIN_EVENTS_INFO_FAILED,
  ADMIN_GET_SPECTATOR_TICKET_REQUEST,
  ADMIN_GET_SPECTATOR_TICKET_SUCCESS,
  ADMIN_GET_SPECTATOR_TICKET_FAILED,
  ADMIN_SPECTATORS_INFO_SUCCESS,
  ADMIN_SPECTATORS_INFO_REQUEST,
} from '../../constants/eventCostants';
import { createConfig, createConfigAccount, formatError } from './utils';

export const getEvents = () => async (dispatch) => {
  try {
    dispatch({ type: GET_EVENTS_REQUEST });

    const config = createConfig();
    const { data } = await backend.get(`/events`, config);

    dispatch({
      type: GET_EVENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_EVENTS_FAILED,
      payload: formatError(error),
    });
  }
};

export const getEvent = (eventId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_EVENT_REQUEST });
    const config = createConfig();
    const { data } = await backend.get(`/event/${eventId}/`, config);

    dispatch({
      type: GET_EVENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_EVENT_FAILED,
      payload: formatError(error),
    });
  }
};

export const createTicket = (eventId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_TICKET_REQUEST });
    let accessToken = getState().userLogin?.user?.access_token;
    const config = createConfigAccount(accessToken);
    const { data } = await backend.put(`/event/${eventId}/ticket/`, {}, config);

    dispatch({
      type: CREATE_TICKET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_TICKET_FAILED,
      payload: formatError(error),
    });
  }
};

export const removeTicketFromCart =
  (ticketId) => async (dispatch, getState) => {
    try {
      dispatch({ type: REMOVE_TICKET_REQUEST });
      let accessToken = getState().userLogin?.user?.access_token;
      const config = createConfigAccount(accessToken);
      const { data } = await backend.put(
        `/event/ticket/remove/`,
        { ticketId },
        config
      );
      dispatch({ type: REMOVE_TICKET_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: REMOVE_TICKET_FAILED,
        payload: formatError(error),
      });
    }
  };

export const createInsurance = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_INSURANCE_REQUEST });
    let accessToken = getState().userLogin?.user?.access_token;
    const config = createConfigAccount(accessToken);
    const { data } = await backend.post(
      `/account/insurance/`,
      { ...formData },
      config
    );

    dispatch({
      type: CREATE_INSURANCE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_INSURANCE_FAILED,
      payload: formatError(error),
    });
  }
};
export const clearInsurance = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_INSURANCE_REQUEST });

    dispatch({
      type: CLEAR_INSURANCE_SUCCESS,
      payload: null,
    });
  } catch (error) {
    dispatch({
      type: CLEAR_INSURANCE_FAILED,
      payload: formatError(error),
    });
  }
};

export const postAthleteInfo =
  (eventId, formData, ticketId) => async (dispatch, getState) => {
    try {
      dispatch({ type: ATHLETE_INFO_REQUEST });
      let accessToken = getState().userLogin?.user?.access_token;
      const config = createConfigAccount(accessToken);
      const { data } = await backend.post(
        `/event/${eventId}/ticket/athlete_info/`,
        { ...formData, ticketId },
        config
      );

      dispatch({
        type: ATHLETE_INFO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ATHLETE_INFO_FAILED,
        payload: formatError(error),
      });
    }
  };

export const clearAthleteInfo = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_ATHLETE_INFO_REQUEST });

    dispatch({
      type: CLEAR_ATHLETE_INFO_SUCCESS,
      payload: null,
    });
  } catch (error) {
    dispatch({
      type: CLEAR_ATHLETE_INFO_FAILED,
      payload: formatError(error),
    });
  }
};

export const getAdminInfo = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ADMIN_EVENTS_INFO_REQUEST });

    let accessToken = getState().userLogin?.user?.access_token;
    const config = createConfigAccount(accessToken);
    const { data } = await backend.get(`/events/admin/info/`, config);

    dispatch({
      type: ADMIN_EVENTS_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_EVENTS_INFO_FAILED,
      payload: formatError(error),
    });
  }
};
export const getSpectatorsInfo = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ADMIN_SPECTATORS_INFO_REQUEST });

    let accessToken = getState().userLogin?.user?.access_token;
    const config = createConfigAccount(accessToken);
    const { data } = await backend.get(`/events/admin/spectators_info/`, config);

    dispatch({
      type: ADMIN_SPECTATORS_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_EVENTS_INFO_FAILED,
      payload: formatError(error),
    });
  }
};

export const getSpectatorTicketInfo = (code) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADMIN_GET_SPECTATOR_TICKET_REQUEST });

    let accessToken = getState().userLogin?.user?.access_token;
    const config = createConfigAccount(accessToken);
    const { data } = await backend.get(
      `/events/admin/spectator_ticket/${code}`,
      config
    );

    dispatch({
      type: ADMIN_GET_SPECTATOR_TICKET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_SPECTATOR_TICKET_FAILED,
      payload: formatError(error),
    });
  }
};
