import React, { useEffect, useState } from 'react';
import { getOrder } from '../../redux/actions/storeActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import LoginForm from '../account/auth/LoginForm';
import SignUpForm from '../account/auth/SignUpForm';
import { getUserDetails } from '../../redux/actions/userActions';

const AccountForm = () => {
  const { error, order, loading } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.userLogin);
  let [formSent, setFormSent] = useState(false);
  let [auth, setAuth] = useState('login');
  const dispatch = useDispatch();
  const [gotOrder, setGotOrder] = useState(false);

  useEffect(() => {
    dispatch(getOrder()).then(() => {
      setGotOrder(true);
    });
    dispatch(getUserDetails());
  }, [dispatch, gotOrder]);

  return (
    <>
      {gotOrder && user && !loading && order?.cart && (
        <Navigate to={order?.needs_shipping ? `../shipping` : '../payment'} />
      )}
      {error && <p>{error}</p>}
      {gotOrder && (
        <>
          <div className='checkoutOptionBtns'>
            <button
              className={`checkoutOptionBtn loginLink ${
                auth === 'login' && 'active'
              }`}
              onClick={() => {
                setAuth('login');
              }}>
              Accedi
            </button>
            <button
              className={`checkoutOptionBtn signupLink ${
                auth === 'signup' && 'active'
              }`}
              onClick={() => {
                setAuth('signup');
              }}>
              Registrati
            </button>
          </div>
          {formSent && user && (
            <Navigate
              to={order?.needs_shipping ? `../shipping` : '../payment'}
            />
          )}
          {auth === 'login' && <LoginForm send={setFormSent} />}
          {auth === 'signup' && (
            <section className='signUpStep'>
              <small className='signUpSmall'>Sprigiona la tua scintilla</small>
              <SignUpForm send={setFormSent} />
            </section>
          )}
          <section>
            <Link to='../anon' className='anonLink'>
              Procedi come ospite
            </Link>
          </section>
        </>
      )}
    </>
  );
};

export default AccountForm;
