import backend from '../../api/backend';
import {
  CONTACT_FAIL,
  CONTACT_REQUEST,
  CONTACT_SUCCESS,
} from '../../constants/infoConstants';
import { createConfig, formatError } from './utils';

export const sendContactForm = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONTACT_REQUEST });
    const config = createConfig();
    const { data } = await backend.post(
      `/emails/contact/`,
      { ...formData },
      config
    );

    dispatch({
      type: CONTACT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_FAIL,
      payload: formatError(error),
    });
  }
};

export const sendPartnersForm = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONTACT_REQUEST });
    const config = createConfig();
    const { data } = await backend.post(
      `/emails/partners/`,
      { ...formData },
      config
    );

    dispatch({
      type: CONTACT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_FAIL,
      payload: formatError(error),
    });
  }
};
