import {
  configureStore,
  combineReducers,
  applyMiddleware,
} from '@reduxjs/toolkit';
import { composeWithDevTools } from '@redux-devtools/extension';
import {
  cartReducer,
  orderReducer,
  productReducer,
  storeReducer,
  paymentIntentReducer,
} from './reducers/storeReducers';
import {
  userDetailsReducer,
  userLoginReducer,
  userSignUpReducer,
  restorePasswordReducer,
  updateAccountReducer,
} from './reducers/userReducers';
import thunk from 'redux-thunk';
import {
  eventReducer,
  eventsReducer,
  createInsuranceReducer,
  athleteInfoReducer,
  adminEventsReducer,
  adminSpectatorTicketReducer,
} from './reducers/eventReducers';
import { contactReducers } from './reducers/infoReducers';

const reducer = combineReducers({
  // Store
  store: storeReducer,
  product: productReducer,
  cart: cartReducer,
  order: orderReducer,
  paymentIntent: paymentIntentReducer,

  // User
  userLogin: userLoginReducer,
  userSignUp: userSignUpReducer,
  userDetails: userDetailsReducer,
  updateAccount: updateAccountReducer,
  restorePassword: restorePasswordReducer,

  // Event
  events: eventsReducer,
  event: eventReducer,
  createInsurance: createInsuranceReducer,
  athleteInfo: athleteInfoReducer,
  adminInfo: adminEventsReducer,
  spectatorTicket: adminSpectatorTicketReducer,

  // Info
  contact: contactReducers,
});

const initialState = {};
const middleware = [thunk];

const store = configureStore(
  { reducer: reducer },
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
