import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminInfo, getSpectatorsInfo } from '../../redux/actions/eventActions';
import Protected from '../../components/Protected';
import { Link } from 'react-router-dom';

const AdminEvents = () => {
  const dispatch = useDispatch();
  const { events, spectators, loading, error } = useSelector((state) => state.adminInfo);

  useEffect(() => {
    dispatch(getAdminInfo());
    dispatch(getSpectatorsInfo());
  }, [dispatch]);

  const renderEvents = (events) => {
    return events
      .filter((event) => event.visible)
      .map((event) => {
        return (
          <Link
            className='adminEvents__link'
            to={`../admin/events/${event.id}`}
            key={event.id}>
            <h3>{event.name}</h3>
          </Link>
        );
      });
  };

  return (
    <main className='adminEvents'>
      <Protected />
      <h1>Eventi</h1>
      {spectators && <h3>Spettatori Assoluto: {spectators.num_tickets}</h3>}
      {events && renderEvents(events)}
    </main>
  );
};

export default AdminEvents;
