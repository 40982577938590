import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  addProductToCart,
  getCart,
  getProduct,
  getStore,
} from '../../redux/actions/storeActions';
import QuantityInput from '../../components/store/QuantityInput';
import Title from '../../components/Title';
import ImageGallery from 'react-image-gallery';
import DiscountPrice from '../../components/store/DiscountPrice';
import SizeInput from '../../components/store/SizeInput';
import ModelInput from '../../components/store/ModelInput';
import ProductsCarousel from '../../layout/store/ProductsCarousel';
import PurifiedMd from '../../components/PurifiedMd';

const getImagesPaths = async (product) => {
  const paths = [];
  for (let i = 0; i < Number(product?.num_images); i++) {
    const { default: path } = await import(
      `../../images/products/${product.images_folder}/${product.model}/${i}.jpg`
    );
    paths[i] = path;
  }
  return paths;
};

const renderPaths = (paths) => {
  const images = [];
  for (let i = 0; i < paths.length; i++) {
    images.push({
      original: paths[i],
      thumbnail: paths[i],
    });
  }
  return images;
};

const Product = () => {
  const { product_id } = useParams();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.cart);
  let quantity =
    products?.find((item) => item.product.id === Number(product_id))
      ?.quantity || 0;

  const { product, loading, error } = useSelector((state) => state.product);

  const [imagesPaths, setImagesPaths] = useState([]);

  useEffect(() => {
    getImagesPaths(product).then(renderPaths).then(setImagesPaths);
  }, [product]);

  useEffect(() => {
    dispatch(getProduct(product_id));
    dispatch(getCart());
    dispatch(getStore());
  }, [dispatch, product_id]);

  const addToCart = (e) => {
    e.stopPropagation();
    dispatch(addProductToCart(product_id));
  };

  return (
    <>
      <Title title={product?.name} />
      <main className='productPage'>
        <div className='productInfo'>
          {error && <h2 className='appError'>{error}</h2>}
          {loading && <div className='spinner'></div>}
          {product && (
            <>
              <ImageGallery showPlayButton={false} items={imagesPaths} />
              <div className='details'>
                <h2>{product.name}</h2>
                <p className='zone'>{product.linea}</p>

                <DiscountPrice element={product} />
                <p className='smallDescription'>{product.brief}</p>
                {product.size && (
                  <SizeInput
                    p_type_id={product.product_type}
                    product={product}
                  />
                )}
                {product.model && (
                  <ModelInput
                    p_type_id={product.product_type}
                    product={product}
                  />
                )}
                {quantity ? (
                  <QuantityInput
                    productId={product_id}
                    quantity={quantity}
                    stock={product.stock}
                  />
                ) : (
                  <button
                    className='btn addToCart'
                    onClick={(e) => addToCart(e)}
                    disabled={quantity >= product.stock}>
                    Aggiungi al carrello
                  </button>
                )}
                <PurifiedMd
                  content={product.description}
                  className='description'
                />
                <Link className='goToCart' to='../store/cart'>
                  Vai al Carrello
                </Link>
              </div>
            </>
          )}
        </div>
        <h1 className='esplora'>Esplora</h1>
        <ProductsCarousel />
      </main>
    </>
  );
};

export default Product;
