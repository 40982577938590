import React from 'react';
import DOMPurify from 'dompurify';
import { marked } from 'marked';

const purifyConfig = {
  ADD_ATTR: ['target'],
};

const PurifiedMd = ({ content, className }) => {
  return (
    <p
      className={className}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(marked(content), purifyConfig),
      }}></p>
  );
};

export default PurifiedMd;
