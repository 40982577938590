import React from 'react';
import { calcDiscountedPrice } from './utils';
import Price from './Price';

const DiscountPrice = ({ element }) => {
  return (
    <div className='price'>
      {element?.stock === 0 && element.id !== 30 ? (
        <p className='soldOut'>Sold Out</p>
      ) : element?.discount ? (
        <>
          <Price amount={calcDiscountedPrice(element)} />
          {element.discount !== 0 && (
            <div className='discount'>
              <Price
                amount={element.price}
                className={`${element.discount ? 'discounted' : ''}`}
              />
              {element.discount.percent && (
                <span className='percent'>
                  - {element.discount.percent * 100}&#37;
                </span>
              )}
              {element.discount.amount && (
                <span className='amount'>
                  - {element.discount.amount}
                  <span className='euro'>&euro;</span>
                </span>
              )}
            </div>
          )}
        </>
      ) : (
        <Price amount={element?.price} />
      )}
    </div>
  );
};

export default DiscountPrice;
