import React, { useState } from 'react';
import Protected from '../../components/Protected';
import QrReader from 'react-qr-scanner';
import { useDispatch, useSelector } from 'react-redux';
import { getSpectatorTicketInfo } from '../../redux/actions/eventActions';
import { AiFillThunderbolt } from 'react-icons/ai';

const SpectatorTicket = () => {
  const { ticket, loading, error } = useSelector(
    (state) => state.spectatorTicket
  );
  const [code, setCode] = useState(null);
  const [isQrReaderOpen, setIsQrReaderOpen] = useState(false);

  const dispatch = useDispatch();

  const handleScan = (data) => {
    console.log(data);
    if (data) {
      let code = data.text;
      setCode(code);
      dispatch(getSpectatorTicketInfo(code));
      setIsQrReaderOpen(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const toggleQrReader = () => {
    setIsQrReaderOpen(!isQrReaderOpen);
  };

  return (
    <main className='spectatorTicket'>
      <button onClick={toggleQrReader} className='btn toggleQr'>
        {isQrReaderOpen ? 'Chiudi Lettore QR' : 'Apri Lettore QR'}
      </button>
      <Protected />
      {loading && <div className='spinner' />}
      {error && <div className='appError'>{error}</div>}

      {isQrReaderOpen && (
        <QrReader
          delay={500}
          className='qrReader'
          onError={handleError}
          onScan={handleScan}
          facingMode='environment'
        />
      )}

      {ticket && !isQrReaderOpen && (
        <div>
          {ticket.entered && (
            <div className='alreadyEntered'>
              <p>Biglietto gia' utilizzato!</p>
            </div>
          )}
          {!ticket.entered && (
            <div className='entering'>
              <p>Accesso consentito</p>
            </div>
          )}
          <button className='btn nextQr' onClick={toggleQrReader}>
            <AiFillThunderbolt className='icon' /> Next
          </button>
        </div>
      )}
    </main>
  );
};

export default SpectatorTicket;
