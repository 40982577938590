import React, { useEffect, useRef } from 'react';
import Title from '../../components/Title';

import Logo from '../../images/logo1000.png';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { sendPartnersForm } from '../../redux/actions/infoActions';
import { emailRegExp, phoneRegExp } from '../../constants/regExp';
import { trimValue } from '../../components/store/utils';

const Partners = () => {
  const { message, error, loading } = useSelector((state) => state.contact);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(sendPartnersForm(data));
    if (message) {
      reset();
    }
  };
  const refs = [useRef(null)];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: [0, 0.25] } // trigger when 50% of the element is visible
    );

    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  });
  return (
    <>
      <Title title={'Sponsor'} />
      <main className='contattiPage'>
        <section className='head'>
          <img className='logo' src={Logo} alt='' />
          <p>CHAMPIONSHIP, SPORTSWEAR AND EQUIPMENT</p>
        </section>
        <section className='body'>
          <section className='contactForm'>
            <h3>Unisciti a noi per fare la storia del Calisthenics</h3>
            {error && <p className='error'>{error}</p>}
            {loading && <div className='spinner' />}
            {message && <p className='success'>{message}</p>}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='slide right'
              ref={refs[0]}>
              <section className={`${errors?.name ? 'wrong' : ''}`}>
                <input
                  type='text'
                  {...register('name', {
                    maxLength: { value: 50, message: 'Nome troppo lungo' },
                    required: 'Campo obbligatorio',
                  })}
                  id='name'
                  autoComplete='one-time-code'
                  placeholder=' '
                />
                <label htmlFor='name'>Nome e Cognome</label>

                {errors?.name && <p className='error'>{errors.name.message}</p>}
              </section>
              <section className='checkbox'>
                <input
                  type='checkbox'
                  {...register('company')}
                  id='company'
                  placeholder=' '
                />
                <label
                  htmlFor='company'
                  id='companyLabel'
                  className='checkboxLabel'>
                  Sono una azienda / attività commerciale
                </label>
                {errors.company && (
                  <p className='error'>{errors.company.message}</p>
                )}
              </section>

              <section className={`${errors.email ? 'wrong' : ''}`}>
                <input
                  type='text'
                  {...register('email', {
                    pattern: {
                      value: emailRegExp,
                      message: 'Email non valida',
                    },
                    maxLength: { value: 150, message: 'Email troppo lunga' },
                    required: 'Campo obbligatorio',
                  })}
                  id='email'
                  autoComplete='off'
                  placeholder=' '
                />
                <label htmlFor='email'>Email</label>
                {errors?.email && (
                  <p className='error'>{errors.email.message}</p>
                )}
              </section>

              <section className={`${errors.phone ? ' wrong' : ''}`}>
                <input
                  type='text'
                  {...register('phone', {
                    pattern: {
                      value: phoneRegExp,
                      message: 'Questo numero non è valido',
                    },
                    required: 'Campo obbligatorio',
                  })}
                  id='phone'
                  autoComplete='one-time-code'
                  placeholder=' '
                  onChange={(e) => trimValue(e)}
                />
                <label htmlFor='phone'>Numero di telefono</label>
                {errors.phone && (
                  <p className='error'>{errors.phone.message}</p>
                )}
              </section>

              <section className={`${errors.email ? 'wrong' : ''}`}>
                <textarea
                  {...register('message', {
                    required: 'Campo obbligatorio',
                  })}
                  placeholder=''
                />
                <label htmlFor='message'>Messaggio</label>
                {errors?.message && (
                  <p className='error'>{errors.message.message}</p>
                )}
              </section>

              <input type='submit' className='btn' value={'Invia'} />
            </form>
          </section>
        </section>
      </main>
    </>
  );
};

export default Partners;
