import React, { useEffect, useState } from 'react';
import { calcTimeDelta, zeroPad } from 'react-countdown';

const Countdown = () => {
  let [time, setTime] = useState(
    calcTimeDelta(new Date('Dec 21, 2024 10:00:00'))
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTime(calcTimeDelta(new Date('Dec 21, 2024 10:00:00')));
    });

    return () => clearTimeout(timeout);
  }, [time]);

  return (
    <section className='countdown'>
      <div className='days'>
        {zeroPad(time?.days)}
        <small>Giorni</small>
      </div>
      <div className='hours'>
        {zeroPad(time?.hours)}
        <small>Ore</small>
      </div>
      <div className='minutes'>
        {zeroPad(time?.minutes)}
        <small>Minuti</small>
      </div>
      <div className='seconds'>
        {zeroPad(time?.seconds)}
        <small>Secondi</small>
      </div>
    </section>
  );
};

export default Countdown;
