import React from 'react';

import postNord from '../../images/events/Nord/base.jpg';
import postCentro from '../../images/events/Centro/base.jpg';
import postSud from '../../images/events/Sud/base.jpg';
import assolutoMobile from '../../images/events/Assoluto/base.jpg';
import assolutoDesktop from '../../images/events/Assoluto/desktop.jpg';
import coppaDesktop from '../../images/events/Coppa Italia/desktop.jpg';
import coppaMobile from '../../images/events/Coppa Italia/base.jpg';
import { Link } from 'react-router-dom';

const Events = ({ isMobile }) => {
  return (
    <>
      <section className='assoluto'>
        <h2>Campionato Italiano</h2>
        <Link to='../store/tickets/Coppa Italia'>
          <img src={isMobile ? coppaMobile : coppaDesktop} alt='' />
        </Link>
      </section>
      <section className='assoluto'>
        <Link to='../store/tickets/Assoluto'>
          <img src={isMobile ? assolutoMobile : assolutoDesktop} alt='' />
        </Link>
      </section>
      <section className='events'>
        <div>
          <Link to='../store/tickets/Nord'>
            <img src={postNord} alt='' />
          </Link>
          <Link to='../store/tickets/Centro'>
            <img src={postCentro} alt='' />
          </Link>
          <Link to='../store/tickets/Sud'>
            <img src={postSud} alt='' />
          </Link>
        </div>
      </section>
    </>
  );
};

export default Events;
