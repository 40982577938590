import React from 'react';
import CostumerForm from '../../components/store/CostumerForm';
import ShippingForm from '../../components/store/ShippingForm';
import InvoiceForm from '../../components/store/InvoiceForm';
import { Link, NavLink, Navigate, Route, Routes } from 'react-router-dom';
import PaymentForm from './PaymentForm';
import { useSelector } from 'react-redux';
import AccountForm from '../../components/store/AccountForm';

import { AiFillThunderbolt } from 'react-icons/ai';

const CheckoutInfo = () => {
  const { user } = useSelector((state) => state.userLogin);
  const { order, loading, error } = useSelector((state) => state.order);
  const details = order?.details;

  return (
    <div className='forms'>
      {((Number(order?.details?.subtotal) < 0 && !loading) ||
        error === 'Ordine non presente') && <Navigate to='../store/cart' />}

      <section id='checkoutSteps'>
        {!user && (
          <div>
            <NavLink to='anon'>Info</NavLink>
            <AiFillThunderbolt className='icon' />
          </div>
        )}

        {order?.needs_shipping && (
          <div>
            {user || order?.email ? (
              <NavLink to='shipping'>Spedizione</NavLink>
            ) : (
              <p>Spedizione</p>
            )}
            <AiFillThunderbolt className='icon' />
          </div>
        )}

        {(order?.needs_invoice || order?.details?.invoice) &&
          ((order?.needs_shipping && order?.shipping_address) ||
            !order?.needs_shipping) && (
            <div>
              <NavLink to='invoice'>Fatturazione</NavLink>
              <AiFillThunderbolt className='icon' />
            </div>
          )}

        <div>
          {/* 
            Render a NavLink to 'payment' if:
            1. 'details?.invoice' is true and 'order' has an 'invoice_address', or 'details' does not have an 'invoice', and
            2. 'order' needs shipping and has a 'shipping_address', or 'order' does not need shipping, and
            3. 'order' needs an invoice and has an 'invoice_address', or 'order' does not need an invoice.
            If these conditions are not met, render a paragraph with 'Pagamento'.
          */}
          {order?.email &&
          ((details?.invoice && order?.invoice_address) || !details?.invoice) &&
          ((order?.needs_shipping && order?.shipping_address) ||
            !order?.needs_shipping) &&
          ((order?.needs_invoice && order?.invoice_address) ||
            !order?.needs_invoice) ? (
            <NavLink to='payment'>Pagamento</NavLink>
          ) : (
            <p>Pagamento</p>
          )}
        </div>
      </section>

      <Routes>
        <Route path='/' element={<AccountForm />} />
        <Route path='/account' element={<AccountForm />} />
        <Route path='/anon' element={<CostumerForm />} />
        <Route path='/shipping' element={<ShippingForm />} />
        <Route path='/invoice' element={<InvoiceForm />} />
        <Route path='/payment' element={<PaymentForm />} />
      </Routes>

      <Link className='returnCart' to='../store/cart'>
        Torna al carrello
      </Link>
    </div>
  );
};

export default CheckoutInfo;
