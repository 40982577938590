import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getEvents } from '../../redux/actions/eventActions';
import Price from '../../components/store/Price';
import { calcDiscountedPrice } from '../../components/store/utils';
import PurifiedMd from '../../components/PurifiedMd';
import { FaTicketAlt } from 'react-icons/fa';
import { getStore } from '../../redux/actions/storeActions';

const TicketsTappa = () => {
  const { events } = useSelector((state) => state.events);
  const { products } = useSelector((state) => state.store);
  const { tappa } = useParams();
  const { user } = useSelector((state) => state.userLogin);
  const tappaEvents =
    tappa === 'Assoluto'
      ? products?.filter(
          (product) =>
            product?.linea?.toLowerCase() === 'biglietti' &&
            product?.name?.toLowerCase() === 'assoluto'
        )
      : events?.filter((event) => event.tappa === tappa);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStore());
    dispatch(getEvents());
  }, [dispatch]);

  const location = useLocation();
  const hash = location.hash?.substring(1);
  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (hash) {
      scrollTo(hash);
    }
    dispatch(getEvents());
  }, [dispatch, hash]);

  const refs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: [0, 0.25] } // trigger when 50% of the element is visible
    );

    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  });

  const renderLinks = (events) => {
    return events?.map((event) => {
      if (!event.tipo) return null;
      return (
        <Link key={event.id} to={`#${event.tipo}`} className='btn'>
          {event.tipo}
        </Link>
      );
    });
  };

  const renderTickets = (events) => {
    if (events.length === 0) return null;
    return events?.map((event, i) => {
      if (event.tipo) {
        return (
          <section
            className={`ticket slide ${i % 2 === 0 ? 'right' : 'left'}`}
            key={event.id}
            id={`${event.tipo}`}
            ref={refs[i]}>
            <h1 className='titleMobile'>{event.tipo}</h1>
            <img
              src={require(`../../images/events/${tappa}/${event.tipo}.jpg`)}
              alt=''
            />
            <div className='content'>
              <h1 className='titleDesktop'>{event.tipo}</h1>
              <div className='costo'>
                <FaTicketAlt />
                <span>Costo biglietto: </span>
                <Price amount={calcDiscountedPrice(event)} className='price' />
                {event?.discount && (
                  <div className='original'>
                    <Price amount={event.price} />
                  </div>
                )}
              </div>
              <PurifiedMd content={event.info} />
              {user ? (
                <Link to={`../store/ticket/${event.id}`} className='btn'>
                  Acquista
                </Link>
              ) : (
                <Link
                  to={`../login?next=/store/ticket/${event.id}`}
                  className='btn'>
                  Accedi e Acquista
                </Link>
              )}
            </div>
          </section>
        );
      }
      return (
        // <Navigate to={`../store/ticket/${event.id}`} />
        <section className='ticket' key={event.id}>
          <img src={require(`../../images/events/${tappa}/base.jpg`)} alt='' />
          <div className='content'>
            <h2>Informazioni</h2>
            <div className='costo'>
              <FaTicketAlt />
              <span>Costo biglietto: </span>
              <Price amount={calcDiscountedPrice(event)} className='price' />
              {event?.discount && (
                <div className='original'>
                  <Price amount={event.price} />
                </div>
              )}
            </div>
            <PurifiedMd
              content={tappa === 'Assoluto' ? event.description : event.info}
            />
            {tappa !== 'Assoluto' &&
              (user ? (
                <Link to={`../store/ticket/${event.id}`} className='btn'>
                  Acquista
                </Link>
              ) : (
                <Link
                  to={`../login?next=/store/ticket/${event.id}`}
                  className='btn'>
                  Accedi e Acquista
                </Link>
              ))}
            {tappa === 'Assoluto' && (
              <Link
                to={
                  'https://www.mailticket.it/manifestazione/KG40/assoluto-italia'
                }
                className='btn'
                target='_blank'
                rel='noreferrer'>
                Acquista
              </Link>
            )}
          </div>
        </section>
      );
    });
  };

  return (
    <main className='ticketsTappa'>
      <h1>
        Ticket{tappaEvents.length > 1 && 's'} Tappa {tappa}
      </h1>
      {tappaEvents.length > 1 && (
        <div className='links'>{renderLinks(tappaEvents)}</div>
      )}
      {renderTickets(tappaEvents)}
    </main>
  );
};

export default TicketsTappa;
