import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminInfo } from '../../redux/actions/eventActions';
import { Link, useParams } from 'react-router-dom';
import Protected from '../../components/Protected';

const AdminEvent = () => {
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const { events } = useSelector((state) => state.adminInfo);
  const event = events?.filter((event) => event.id === Number(eventId))[0];

  useEffect(() => {
    dispatch(getAdminInfo());
  }, [dispatch]);

  const renderTickets = (tickets) => {
    return tickets
      .filter((ticket) => ticket.status === 'active')
      .map((ticket) => {
        return (
          <li key={ticket.id}>
            <Link to={`../admin/events/${event.id}/${ticket.id}`}>
              <h4>
                {ticket.user.first_name} {ticket.user.last_name}
              </h4>
            </Link>
          </li>
        );
      });
  };

  return (
    <>
      <Protected />
      {event && (
        <main>
          <h1>{event.name}</h1>
          <p>
            Posti disponibili: {event.available_places}/{event.places}
          </p>
          <p>
            Vendita biglietti: dal {event.ticket_start_date} al{' '}
            {event.ticket_end_date}
          </p>
          <h2>Atleti:</h2>
          <ol>{event.tickets && renderTickets(event.tickets)}</ol>
          <div>
            <Link className='adminEvents__back' to={`../admin/events`}>
              indietro
            </Link>
          </div>
        </main>
      )}
    </>
  );
};

export default AdminEvent;
