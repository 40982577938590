import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Price from './Price';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearAthleteInfo,
  clearInsurance,
  removeTicketFromCart,
} from '../../redux/actions/eventActions';
import Protected from '../Protected';
import { getUserDetails } from '../../redux/actions/userActions';

const TicketItem = ({ ticket }) => {
  const { tickets, loading } = useSelector((state) => state.cart);
  const { insurance } = useSelector((state) => state.createInsurance);
  const { loadingAthleteInfo, data } = useSelector(
    (state) => state.athleteInfo
  );
  const { user, loading: loadingUser } = useSelector(
    (state) => state.userDetails
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const onClick = (e) => {
    e.stopPropagation();
    dispatch(clearInsurance());
    dispatch(clearAthleteInfo());
    dispatch(removeTicketFromCart(ticket.id));
  };

  const IMAGES = {
    Nord: () => import(`../../images/tickets/miniatura_Nord.jpg`),
    Centro: () => import(`../../images/tickets/miniatura_Centro.jpg`),
    Sud: () => import(`../../images/tickets/miniatura_Sud.jpg`),
    Assoluto: () => import(`../../images/tickets/miniatura_Assoluto.jpg`),
    'Coppa Italia': () =>
      import(`../../images/tickets/miniatura_Coppa Italia.jpg`),
  };

  const [tappaImage, setTappaImage] = useState(null);

  useEffect(() => {
    const loadTappaImage = async () => {
      const imageImport = IMAGES[ticket.tappa];
      if (imageImport) {
        const imageModule = await imageImport();
        setTappaImage(imageModule.default);
      }
    };
    loadTappaImage();
  });

  const checkInsurance = () => {
    for (let i = 0; i < tickets?.length; i++) {
      if (tickets[i].insurance !== null) {
        return true;
      }
    }
    return false;
  };

  return (
    <section className='ticketItem'>
      <Protected />
      {!loadingAthleteInfo &&
        ticket?.needs_athletes_info &&
        !(ticket?.athlete_info || data?.event === ticket?.event_id) && (
          <Navigate to={`../store/ticket/${ticket.event_id}/athlete_info/`} />
        )}
      {!loadingUser &&
        !user?.insurance &&
        !loading &&
        ticket?.with_insurance &&
        !ticket.insurance &&
        !insurance &&
        !checkInsurance() && <Navigate to='../account/insurance' />}
      <img src={tappaImage} alt='' />
      <p className='productName'>
        {ticket.tappa} {ticket.tipo}
        <small>Biglietto</small>
        {ticket.insurance && (
          <>
            <br />
            <small>+ assicurazione</small>
          </>
        )}
      </p>

      {ticket.insurance && !loading ? (
        <Price amount={ticket.price + 30} className='productTotal' />
      ) : (
        <Price amount={ticket.price} className='productTotal' />
      )}
      <button onClick={(e) => onClick(e)} className='Xremove'>
        <AiOutlineClose />
      </button>
      <button onClick={(e) => onClick(e)} className='remove'>
        Rimuovi
      </button>
    </section>
  );
};

export default TicketItem;
