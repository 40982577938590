import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentIntent } from '../../redux/actions/storeActions';
import { Link, Navigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentForm from '../../components/store/StripePaymentForm';
import { DEBUG } from '../..';

const PaymentForm = () => {
  const {
    error,
    order,
    loading: orderLoading,
  } = useSelector((state) => state.order);
  const {
    clientSecret,
    loading,
    error: errorPaymentIntent,
  } = useSelector((state) => state.paymentIntent);
  let stripeKey = DEBUG
    ? 'pk_test_51O2gJ3LEZMHpft2F2Za9uddEg3hzU2FCNhpAcsk43CFNBM30e27HDiqngtANn13HPbRp6U5fK3jIio06J2VbBd5m00JBIkBbXe'
    : 'pk_live_51O2gJ3LEZMHpft2FUxy1RcL1WaLGpRfiVEaXy8F8C0diSLquf7ETKjhjrsSMq25911hNcYq1PhVLs8neVzNtsQxH00uFRZKal0';
  const stripePromise = loadStripe(stripeKey);
  const dispatch = useDispatch();
  let [gotPaymentIntent, setGotPaymentIntent] = useState(false);

  useEffect(() => {
    if (
      !gotPaymentIntent &&
      !orderLoading &&
      ((order?.needs_shipping && order?.shipping_address) ||
        !order.needs_shipping) &&
      ((order?.needs_invoice && order?.invoice_address) || !order.needs_invoice)
    ) {
      dispatch(getPaymentIntent());
      setGotPaymentIntent(true);
    }
  }, [dispatch, gotPaymentIntent, orderLoading, order]);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
    appearance: { theme: 'night', labels: 'floating' },
  };

  return (
    <section className='paymentForm'>
      {/* Redirect to shipping if shipping is needed but shipping address is not present */}
      {!orderLoading && order?.needs_shipping && !order.shipping_address && (
        <Navigate to='../shipping' />
      )}

      {/* Redirect to invoice if invoice is needed but invoice address is not present */}

      {!orderLoading && order?.needs_invoice && !order.invoice_address && (
        <Navigate to='../invoice' />
      )}

      {error && <p className='error'>{error}</p>}

      <h2>Pagamento</h2>
      {errorPaymentIntent && <p className='error'>{errorPaymentIntent}</p>}
      {loading && <div className='spinner' />}
      <>
        {clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <StripePaymentForm />
          </Elements>
        )}
      </>

      {!order?.needs_shipping && !order.needs_invoice && (
        <Link to='../invoice' className='invoiceLink'>
          Voglio la fattura
        </Link>
      )}
    </section>
  );
};

export default PaymentForm;
